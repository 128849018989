import { RouteObject } from "react-router-dom"; 
import { AuthRoute , authGuard } from "@common/auth/guards/auth-route"; 
 

const lazyDriveRoute = async (
  cmp: keyof typeof import("@app/cse_search/routes.lazy")
) => {
  const exports = await import("@app/cse_search/routes.lazy");
  return {
    Component: exports[cmp],
  };
};

export const CseRoutes: RouteObject[] = [
  {
    path: "gg-search",
    // loader: () => authGuard({permission: 'users.view'}),
    lazy: () => lazyDriveRoute("ListingLayout"),
    element: <AuthRoute />,
    children: [
      {
        index: true,
        lazy: () => lazyDriveRoute("CseSearchPage"),
      }, 
      {
        path: "edit",
        lazy: () => lazyDriveRoute('InputPageSearch'),
        // lazy: () => lazyDriveRoute("EditCustomPage"),
      },
    ],
  },
];
